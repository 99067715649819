import React from "react";

const Notfound = () => {
    
    return ( 
        <div className="FourOhFour">
            <h3>Страница не найдена!</h3>
            <div className="bg"></div>
            <div className="code">404</div>
            <a href="/">Back to Home</a>
        </div>
        );
}
 
export default Notfound;
