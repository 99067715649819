
export const Contact = (props) => {
  
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-12'>
              <div className='row'>
                <div className='col-md-6 col-md-offset-1 contact-info'>
                  <div className='contact-item'>
                    <h3>Мы на картах Yandex!</h3>
                    <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=169403061618" title="yandex.map" ></iframe>
                  </div>
                </div>
                <div className='col-md-3 col-md-offset-1 contact-info'>
                  <div className='contact-item'>
                    <h3>Контакты</h3>
                    <p>
                      <span>
                        <i className='fa fa-map-marker'></i> Адресс
                      </span>
                      {props.data ? props.data.address : 'loading'}
                    </p>
                  </div>
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-phone'></i> Телефон
                      </span>{' '}
                      <a href={props.data ? "tel:" + props.data.phone : 'loading'}>{props.data ? props.data.phone : 'loading'}</a>
                    </p>
                  </div>
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-phone'></i> Телефон
                      </span>{' '}
                      <a href={props.data ? "tel:" + props.data.phone1 : 'loading'}>{props.data ? props.data.phone1 : 'loading'}</a>
                    </p>
                  </div>
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-phone'></i> Телефон
                      </span>{' '}
                      <a href={props.data ? "tel:" + props.data.phone2 : 'loading'}>{props.data ? props.data.phone2 : 'loading'}</a>
                    </p>
                  </div>
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-envelope-o'></i> Email
                      </span>{' '}
                      <a href={props.data ? "mailto:" + props.data.email : 'loading'}>{props.data ? props.data.email : 'loading'}</a>
                      
                    </p>
                  </div>
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-briefcase'></i> ИНН
                      </span>{' '}
                      {props.data ? props.data.inn : 'loading'}
                    </p>
                  </div>
                  <div className='contact-item'>
                    <p>
                      <span>
                        <i className='fa fa-credit-card'></i> Р/Счет
                      </span>{' '}
                      {props.data ? props.data.r_ch : 'loading'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}
