import React, { useEffect, useState } from "react";
import { FiList, FiXSquare } from "react-icons/fi";
import NavBarItem from "./NavBarItem";


export function NavBar(props) {
  
  const menu  = props.data;

  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 964 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  return (
    <header className="header">
      <div className="header__content">
        <nav
          className={`${"header__content__nav"}${menuOpen && size.width < 964 ? `${" isMenu"}` : ""}`}
        >
          <a href="/" 
            className={`${"header__content__nav"}${menuOpen && size.width < 964 ? `${" isMenu"}${"__logo"}` : "__logo"}`}
          >
            <img  src='/img/logo.svg' alt='logo'/>
          </a>
          <ul>
            <NavBarItem menu={menu} />
          </ul>
        </nav>
        <div className="header__content__toggle">
          {!menuOpen ? (
            <div onClick={menuToggleHandler} ><FiList /></div>
          ) : (
            <div onClick={menuToggleHandler} ><FiXSquare /></div>
          )}
        </div>
      </div>
    </header>
  );
}