import { MySlider } from "../components";

export const WorkAs = (props) => {
    return (
      <div id='workas' className='text-center'>
        <div className='container'>
          <div className="row">
            <div className='col-md-8 col-md-offset-2 section-title'>
              <h2>С кем мы работаем?</h2>
              <p>
                Наши партнеры и коллеги взаимодейцствуют с нами!
              </p>
            </div>
          </div>
          <div className="row">
            <MySlider data = {props}/>
          </div>
        </div>
      </div>
    )
  }