export const Footer = () => {
    return ( 
        <div id='footer'>
            <div className='container text-center'>
            <p>
                &copy; 2019 - {new Date().getFullYear()} All rights reserved. Design by{' '}
                <a href='/' rel='nofollow'>
                STK-PROGRESS.RU
                </a>
            </p>
            </div>
        </div>
     );
}
