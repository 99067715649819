export const Testimonials = (props) => {
  
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Что о нас говорят</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              i < 3 ? CardComment(d, i) : ''
              ))
            : 'loading'}
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
             i >= 3 ? CardComment(d, i) : ''
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}

function CardComment(data, index)
{
  return (
    <div key={index} className='col-md-4'>
      <div className='testimonial'>
        <div className='testimonial-image'>
          {' '}
          <img src={data.img} alt={data.img} />{' '}
        </div>
        <div className='testimonial-content'>
          <p>"{data.text}"</p>
          <div className='testimonial-meta'> - {data.name} </div>
        </div>
      </div>
    </div>
  )
}