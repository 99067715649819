import React from "react";

class  MySlider extends React.Component {

    render() { 
        return ( 
        <div className="slider">
            <div className="slide-track">
                {this.props.data.data ? this.props.data.data.map(( d, i ) => (
                    <div key={i} className="slide">
                        <img src={d.img} height={d.height} width={d.width} alt={d.alt} />
                    </div>
                ))
                : 'Loading...'}
                 {this.props.data.data ? this.props.data.data.map(( d, i ) => (
                    <div key={i} className="slide">
                        <img src={d.img} height={d.height} width={d.width} alt={d.alt} />
                    </div>
                ))
                : 'Loading...'}
            </div>
        </div> 
        );
    }
}
 
export default MySlider;