import { FiPhoneCall } from 'react-icons/fi';
import { BsTruck, BsArchive } from 'react-icons/bs';
import { ImFileText2 } from 'react-icons/im';

export const Services = (props) => {
  const ico = [<FiPhoneCall />, <BsTruck />, <BsArchive />, <ImFileText2 />]
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Наши услуги</h2>
          <p>
            
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3'>
                  {' '}
                  <i className='fa'>{ico[i]}</i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    {d.text.map((dt, j) => (
                      <div key={j}>
                        <p>{dt}</p>
                        <hr></hr>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
