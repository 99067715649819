import React, {useState, useEffect} from 'react';
import {MySliderGallery}  from '../components';
import { SmallContact } from '../section';
import { NavBar } from '../components';

import JsonDataMenu from "../data/menu_back.json";

export const AllPhoto = (props) => {

  const [MenuData, setMenuData] = useState({});

  useEffect(() => {

    setMenuData(JsonDataMenu);

  }, []);

  return (
    <div>
      <NavBar data = {MenuData.TopMenu1} />
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Галерея</h2>
            <p>
              Как работает наша техника
            </p>
          </div>
          <div className='row'>
            <div id='portfoliogallery' className='portfolio-items'>
              <MySliderGallery data={props.data.DynamGallery}/>
            </div>
          </div>
        </div>
      </div>
      <SmallContact data={props.data.Contact}/>
    </div>
  );
}