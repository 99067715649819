import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Footer } from './section';
import {Home, AllPhoto, Notfound} from './pages';
import SmoothScroll from "smooth-scroll";
import "./scss/custom.scss";

import JsonData from "./data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(JsonData);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLandingPageData(JsonData);
    setLoading(false);
  }, []);

  return (
    <BrowserRouter>
        {loading ? (
          <div className="loader-container">
              <div className="spinner"></div>
          </div>
        ) : (
          <div>
            
            <Routes>
                  <Route path="*" element={<Notfound />} />
                  <Route path='/' element={ <Home data = {landingPageData}/>} />
                  <Route path='/allphoto' element={ <AllPhoto data = {landingPageData}/>} />
            </Routes>
            <Footer />
          </div> 
        )}
    </BrowserRouter>
  );
};

export default App;
