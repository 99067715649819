import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';


export function Gallery(props) {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div id='sections' className='section-title'>
          <h2>Галерея</h2>
          <p>
            Как работает наша техника
          </p>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            <div className="containers">
              {props.data.map((image, i) => (
                <div key={i} className="card">
                  <Image src={image.largeImage} />
                  <Link to={'allphoto'}>
                    <div className="card__head">{image.title}</div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}