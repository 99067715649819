import React, { useState } from "react";

function NavBarItem(props) {
    
    const menu = props.menu;

    const [active, setActive] = useState(0);

    return ( 
    <>
        {menu ? menu.map((item, id) => (
              <li key={id} className={active === id ? "active" : ""}>
                <a href={item.path} onClick={() => setActive(id)}>{item.name}</a>
              </li>
            ))
        : "Loading..."}
    </> 
    );
}

export default NavBarItem;