import React, {useState, useEffect} from 'react';
import { Header, Features, About, Services, Gallery, Testimonials, WorkAs, Contact} from '../section';
import { NavBar } from '../components';

import JsonDataMenu from "../data/menu.json";

export const Home = (props) => {

    const [MenuData, setMenuData] = useState({});

    useEffect(() => {

        setMenuData(JsonDataMenu);

      }, []);
  
    return (
        <div>
            <NavBar data = {MenuData.TopMenu} />
            <Header data={props.data.Header} />
            <Features data={props.data.Features} />
            <About data={props.data.About} />
            <Services data={props.data.Services} />
            <Gallery data={props.data.Gallerys}/>
            <Testimonials data={props.data.Testimonials} />
            <WorkAs data={props.data.Workas} />
            <Contact data={props.data.Contact} />
        </div>
    );
}